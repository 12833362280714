<template>
  <div>
    <router-view></router-view>
    <van-tabbar route>
      <van-tabbar-item replace to="/home" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item
        replace
        to="/Shopping"
        icon="shopping-cart-o"
        :badge="$store.state.login.count"
        >购物车</van-tabbar-item
      >
      <van-tabbar-item replace to="/my" icon="friends-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<style>
</style>

<script>

export default {
  data () {
    return {

    }
  }
};
  //:key="$route.fullPath"
</script>